
body{
    background-color: #fafdfb !important;
}

.modal-social{
    /* background-color: red !important; */
    z-index: 2000;
    position: absolute;
    display: flex;
    justify-content: center;
    text-align: center;
    justify-items: center;
}