.modalMask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    z-index: 999;
    align-items: center;
}

.modalBody{
    /* width: 70%; */
    /* width: 35%; */
    max-width: 1000px;
    background: white;
    max-height: 80vh;
    overflow: auto;
}

@media only screen and (max-width: 800px) {
    .modalBody{
        width: 85%;
        max-width: 1000px;
        background: white;
        max-height: 80vh;
    }
  }